<template>
	<div class="center cart-page">
		<h3>Корзина</h3>
		<div v-if="productList" class="row center cart-row ">
			<div class="cart-table ">
				<table>
					<thead></thead>

					<tbody>
						<tr v-for="(product, index) in productList">
							<td>
								<div
									class="table-item center col s12 m12 xl12 l12 center"
								>
									<div class=" title-product-cart center">
										<router-link
											:to="
												`/product/${product.productId}`
											"
											tag="a"
											class="title-item"
										>
											{{ product.productTitle }}
										</router-link>
									</div>

									<div
										class="center center-align"
										@click="toProduct(product)"
									>
										<img
											:src="product.productImage"
											class="responsive-img center img-cart-item"
											alt=""
										/>
									</div>

									<div class="amount-product-cart center">
										<div class="sum-span">
											<span class="calc">
												{{ product.amount }} x
												{{ product.price | currency }}
											</span>
											<span class="result">
												{{
													(product.price *
														product.amount)
														| currency
												}}
											</span>
										</div>

										<div class="product-buy  row">
											<div class="amount-span">
												<p>{{ product.amount }}</p>
											</div>

											<button
												class="btn btn-block reduce btn-flat  waves-effect"
												@click.prevent="
													decCount(product)
												"
												:class="{
													disabled:
														product.amount <= 1,
												}"
											>
												<i class="material-icons">
													remove
												</i>
											</button>

											<button
												class="btn btn-block add  btn-flat waves-effect "
												@click.prevent="
													incCount(product)
												"
											>
												<i class="material-icons">
													add
												</i>
											</button>

											<button
												class="removeFromCart"
												@click.prevent="
													removeProductFromCart(
														product,
													)
												"
											>
												<i class="material-icons">
													delete_outline
												</i>
											</button>
										</div>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="cart-result" :class="{ filled: productList.length }">
				<div class="result-container">
					<div class="result-holder ">
						<div class="amount">
							<div class="title">Итого:</div>
							<div class="sum">{{ gerCartSum | currency }}</div>
						</div>
						<div class="shadow"></div>

						<div
							@click="toCheckout"
							class="redirect waves-effect waves-light"
						>
							<i class="material-icons right">
								arrow_forward_ios</i
							>
							<p class="ok">Оформить</p>
							<!--                            <p class="material-icons ok">assignment</p>-->
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else>
			<h4 class="center">Корзина пуста</h4>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		name: 'cart',
		cart: [],
	}),

	methods: {
		toCheckout() {
			this.$router.push('/checkout');
			window.scroll({
				top: 0,
				left: 0,
			});
		},
		toProduct(product) {
			this.$router.push(`/product/${product.productId}`);
			window.scroll({
				top: 0,
				left: 0,
			});
		},
		incCount(product) {
			this.$store.commit('incProductCount', product);
		},

		decCount(product) {
			this.$store.commit('decProductCount', product);
		},

		removeProductFromCart(product) {
			this.$store.commit('deleteProductFormCart', product);
		},

		clearCart() {
			this.$store.commit('clearCart');
		},
	},

	computed: {
		productList() {
			return this.$store.getters.getCart;
		},
		gerCartSum() {
			return this.$store.getters.gerCartSum;
		},
	},
	watch: {
		productList: {
			handler() {
				if (this.gerCartSum === 0) {
					this.$router.push('/');
					window.scroll({
						top: 0,
						left: 0,
					});
				}
			},
		},
	},
};
</script>

<style scoped lang="less">
@mobileMenuBreakpoint: 650px;
@text-color: rgb(26, 26, 24);
@white: rgb(255, 255, 255);
@red: #f35858;
@black: #343434;
.product-buy {
	z-index: 0;
	box-shadow: 0 3px 35px rgba(0, 0, 0, 0.25) !important;
}
h3 {
	margin: 4rem;
	font-weight: 500;
}
.cart-page {
	padding: 1rem;
	margin-bottom: 15vh;
}
tr {
	border: none;
}
/*===========================  КАРТОЧКИ ====================*/

@media screen and (max-width: 800px) {
	.cart-row {
		transition: all 0.2s;

		display: flex;
		align-items: center;
		justify-content: center;

		.cart-table {
			transition: all 0.2s;
			display: flex;
			align-items: center;
			justify-content: center;

			.table-item {
				border-radius: 10px;
				box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
				width: 80vw;
				max-width: 28rem;
				position: relative;
				display: flex;
				align-content: center;
				align-self: center;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.img-cart-item {
					text-align: center;
					height: 15rem;

					&:hover {
						opacity: 0.9;
						transition: opacity 0.2s;
					}
				}

				.title-item {
					color: #4d4d4d;
					line-height: 90px;
					height: 20px;
					width: 100%;
					font-size: 25px;
					font-weight: 600;
					transition: color 0.4s;

					&:hover {
						transition: color 0.4s;
						color: #fd5949;
					}
				}

				.amount-product-cart {
					height: 12rem;
					position: relative;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;

					.sum-span {
						display: flex;
						flex-direction: column;
						width: 60vw;
						height: 100%;
						padding: 15px 15px 10px 20px;

						.calc {
							letter-spacing: 2px;
							color: grey;
							font-size: 18px;
							font-weight: 500;
							font-style: italic;
						}

						.result {
							letter-spacing: 3px;
							font-weight: 700;
							color: rgba(253, 89, 73, 1);
							padding-top: 5px;
							font-size: 2.2rem;
							text-transform: uppercase;
							text-shadow: 1px 1px 2px rgba(92, 81, 80, 0.32),
								-1px -1px 1px rgba(255, 255, 255, 0.9);
						}
					}

					.product-buy {
						background-color: #f9f9f9 !important;
						box-shadow: 0 0 7px #d1d1d1 !important;
						position: absolute;
						bottom: -5px;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						display: inline-flex;
						flex-direction: row;
						width: 9.3rem;
						height: 3.5rem;
						border-radius: 10px;

						.disabled {
							background-color: #f9f9f9 !important;
						}
						.add,
						.reduce {
							border-radius: 10px;
							background-color: #f9f9f9 !important;
							height: 3.5rem;
							width: 5rem;
							i {
								color: grey;
								margin-top: 5px;
								font-size: 2rem;
							}
						}

						.amount-span {
							position: absolute;
							bottom: 30px;
							right: 115%;
							height: 3rem;
							width: 40px;

							p {
								color: #4b4b4b;
								line-height: 3rem;
								height: 3rem;
								width: 40px;
								font-size: 25px;
								font-weight: 600;
							}
						}

						.removeFromCart {
							position: absolute;
							top: 2px;
							left: 107%;
							height: 3rem;
							width: 5rem;
							background-color: transparent;
							border: none;

							i {
								border-radius: 50px;
								color: red;
								font-weight: 500;
								font-size: 30px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 801px) {
	.cart-row {
		.cart-table {
			display: flex;
			align-items: center;
			justify-content: center;

			.table-item {
				width: 100%;
				position: relative;
				display: flex;
				flex-direction: row;
				justify-content: space-around;

				.img-cart-item {
					box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
					margin: 2rem;
					bottom: 0;
					text-align: center;
					max-width: 15rem;
					height: 18rem;
					object-fit: cover;

					&:hover {
						opacity: 0.8;
						transition: opacity 0.3s;
					}
				}

				.title-item {
					position: absolute;
					top: 2.5rem;
					left: 37%;
					text-align: center;
					color: #4d4d4d;
					line-height: 30px;
					height: 50px;
					width: 20rem;
					font-size: 25px;
					font-weight: 600;
					transition: color 0.4s;

					&:hover {
						transition: color 0.4s;
						color: #fd5949;
					}
				}

				.amount-product-cart {
					right: 0;
					height: 14rem;
					position: relative;
					margin: 5rem 0 0 0rem;
					display: flex;
					justify-content: space-between;
					flex-direction: column;
					width: 60%;

					.sum-span {
						position: relative;
						display: flex;
						flex-direction: row;
						justify-content: left;
						height: 1rem;
						line-height: 10px;
						left: 1.9rem;
						top: 6rem;
						right: 50px;

						.calc {
							letter-spacing: 2px;
							color: grey;
							font-size: 18px;
							font-weight: 500;
							font-style: italic;
						}

						.result {
							letter-spacing: 3px;
							margin-left: 20px;
							font-weight: 700;
							color: rgba(253, 89, 73, 1);
							font-size: 1.9rem;
							text-transform: uppercase;
							text-shadow: 1px 1px 2px rgba(92, 81, 80, 0.32),
								-1px -1px 1px rgba(255, 255, 255, 0.9);
						}
					}
					.product-buy {
						background-color: #f9f9f9 !important;
						box-shadow: 0 0 7px #d1d1d1 !important;
						position: absolute;
						bottom: -5px;
						left: 5rem;

						margin-left: auto;
						margin-right: auto;
						display: inline-flex;
						flex-direction: row;
						width: 9.5rem;
						height: 3.5rem;
						border-radius: 10px;

						.disabled {
							background-color: #f9f9f9 !important;
						}
						.add,
						.reduce {
							border-radius: 10px;
							background-color: #f9f9f9 !important;
							height: 3.5rem;
							width: 5rem;
							i {
								color: grey;
								margin-top: 5px;
								font-size: 2rem;
							}
						}

						.amount-span {
							position: absolute;
							bottom: 30px;
							right: 153px;
							height: 3rem;
							width: 40px;

							p {
								color: #4b4b4b;
								line-height: 3rem;
								height: 3rem;
								width: 40px;
								font-size: 25px;
								font-weight: 600;
							}
						}

						.removeFromCart {
							position: absolute;
							top: 2px;
							left: 145px;
							height: 3rem;
							width: 5rem;
							background-color: transparent;
							border: none;

							i {
								border-radius: 50px;
								color: red;
								font-weight: 500;
								font-size: 30px;
							}
						}
					}
				}
			}
		}
	}
}
/*===========================  КАРТОЧКИ ====================*/

/*=========================НИЖНИЙ БЛОК С РЕДИРЕКТОМ====================*/
@media screen and (max-width: 900px) {
	.cart-result {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		right: 0;
		left: 0;
		width: 100%;
		bottom: 30px;
		z-index: 800;

		.result-container {
			border-radius: 20px;
			background-color: white;
			border: 6px solid white;
			box-shadow: inset 3px 3px 15px rgba(191, 184, 190, 0.48),
				inset -3px -3px 17px #ffffff,
				3px 3px 17px rgba(131, 121, 128, 0.48);
			justify-content: center;
			transform: translateY(30vh);
			transition: transform 0.3s ease;
			width: 92%;

			max-width: 26rem;
			height: 85px;
			display: flex;
			flex-direction: row;

			.result-holder {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.amount {
					display: flex;
					flex-direction: column;
					width: 100%;
					height: 90%;
					z-index: 750;
					padding-left: 5%;

					.title {
						color: #bcbcbc;
						margin-top: 8px;
						font-size: 20px;
						font-weight: bold;
						font-style: italic;
						text-transform: uppercase;
						align-self: flex-start;
					}

					.sum {
						left: 0;
						color: #838383;
						letter-spacing: 2px;
						float: left;
						font-weight: bold;
						font-size: 23px;
						align-self: flex-start;
					}
				}

				.redirect {
					box-shadow: -10px -6px 6px rgba(229, 229, 229, 0.6);
					background: linear-gradient(
						69.08deg,
						#00ed8f 2.63%,
						#00c459 132.25%
					);
					z-index: 755;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					align-content: center;
					top: 2px;
					right: 2px;
					height: 69px;
					border-radius: 12px;
					width: 100%;
					max-width: 16rem;
					min-width: 11rem;

					.ok {
						opacity: 1;
						transition: opacity 0.2s;
						font-size: 15px;
						letter-spacing: 1px;
						padding-left: 14%;
						font-weight: 600;
						line-height: 0;
						height: 0;
						margin: 0;
						text-transform: uppercase;
						color: white;
					}

					.right {
						position: absolute;
						transition: transform 0.3s ease;
						width: 1px;
						height: 15px;
						font-size: 20px;
						color: white;
						right: 30px;
					}
				}
			}
		}
	}
	.cart-result.filled {
		.result-container {
			transition: transform 0.3s ease;
			transform: translateY(0);
		}
	}
}

@media screen and (min-width: 901px) {
	.cart-result {
		.result-container {
			transform: translateX(130vh);
			transition: transform 0.3s ease;

			position: fixed;
			height: 15rem;
			width: 27vw;
			top: 28rem;
			right: 3vw;
			border-radius: 16px;
			border: 6px solid white;
			box-shadow: inset 1px -3px 25px rgba(191, 184, 190, 0.28),
				inset -1px -3px 27px #ffffff,
				0px 0px 77px rgba(131, 121, 128, 0.38);

			.result-holder {
				border-radius: 16px;
				z-index: 600;
				height: 100%;
				justify-content: space-between;
				display: flex;
				flex-direction: column;
				text-align: center;

				/*.shadow{*/
				/*    position: absolute;*/
				/*    bottom: 82px;*/
				/*    margin-top: 10px;*/
				/*    transform: rotate(-7.8deg);*/
				/*    background-color: white;*/
				/*    z-index: 380;*/
				/*    height: 25px;*/
				/*    width:95%;*/
				/*    right: 9px;*/
				/*    box-shadow: 1px 25px 29px rgba(0, 0, 0, .2);*/
				/*    padding-right: 20px;*/
				/*}*/

				.amount {
					justify-content: space-between;
					display: flex;
					flex-direction: column;
					width: 100%;
					float: left;
					align-items: flex-start;
					padding-left: 15%;
					z-index: 500;

					.title {
						color: #969696;
						margin-top: 30px;
						font-size: 22px;
						font-weight: bold;
						font-style: italic;
						text-transform: uppercase;
					}

					.sum {
						margin-top: 1rem;
						color: #5e5e5e;
						letter-spacing: 2px;
						font-size: 26px;
						font-weight: bold;
					}
				}

				.redirect {
					border-radius: 0 0 12px 12px;
					z-index: 350;
					justify-content: space-between;
					display: flex;
					flex-direction: row;
					width: 100%;
					align-items: flex-end;
					box-shadow: 0 0 15px rgba(244, 102, 0, 0.22),
						0px 0px 16px rgba(255, 255, 255, 0.6);
					background: linear-gradient(
						69.08deg,
						#00ed8f 2.63%,
						#00c459 132.25%
					);
					-webkit-clip-path: polygon(
						0 40%,
						100% 0%,
						100% 100%,
						0% 100%
					);
					clip-path: polygon(0 40%, 100% 0%, 100% 100%, 0% 100%);
					height: 50%;

					i {
						color: white;
						position: absolute;
						right: 10%;
						height: 45px;
					}

					p {
						color: white;
						font-weight: 600;
						height: 25px;
						font-size: 28px;
						margin-left: 55px;
					}
				}
			}
		}
	}
	.cart-result.filled {
		.result-container {
			transition: transform 0.3s ease;
			transform: translateY(0);
		}
	}
}
</style>
